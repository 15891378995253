// @flow

import axios from 'axios'

export type ProgramPayload = {|
  programId: string,
  label: string,
  location: string,
  links: {
    program_preview?: string,
    lot_catalog: string,
    product_catalog?: string,
    product3d_catalog?: string,
    contact?: string,
    privacy_policy?: string,
    terms_of_use?: string,
  },
  developerName: string,
  numberOfLots: number,
|}

export type Lot = {|
  externalId?: string,
  slug: string,
  area: number,
  available: boolean,
  commercialized: boolean,
  floorLabel: string,
  floorId: string,
  buildingId: string,
  buildingLabel: string,
  label: string,
  links?: {
    plan?: string,
    configurator?: string,
    picture?: string,
  },
  lotId: string,
  price: {|
    amountIncludingTaxes: number,
    vatInPercent: number,
    currency: string,
    amountExcludingTaxes: number,
    vatList: number[],
  |},
  totalPrice: {|
    amountIncludingTaxes: number,
    vatInPercent: number,
    currency: string,
    amountExcludingTaxes: number,
    vatList: number[],
  |},
  hidePrice: boolean,
  typology: string,
  parkings: [],
  extra: ?{ [key: string]: string },
|}

export const fetchProgram = async (
  programHostname: string,
): Promise<ProgramPayload> => (await axios.get(programHostname)).data

export const fetchLotCatalog = async (
  lotCatalogApiEndpoint: string,
): Promise<Lot[]> => (await axios.get(lotCatalogApiEndpoint)).data
