// @flow

const BrandColor: string = process.env.REACT_APP_BRAND_COLOR || '#01acb1'

export const Colors = {
  // Deprecated colors,
  blackRegular: '#3d3d3d', // TODO: to be deleted
  inputBackground: '#2b2b2b', // TODO: to be deleted

  brand: BrandColor,
  red: '#ff5858',

  black100: '#000000',
  black85: 'rgba(0, 0, 0, 0.85)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black35: 'rgba(0, 0, 0, 0.35)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black10: 'rgba(0, 0, 0, 0.1)',
  black5: 'rgba(0, 0, 0, 0.05)',

  white100: '#ffffff',
  white90: 'rgba(255, 255, 255, 0.9)',
  white85: 'rgba(255, 255, 255, 0.85)',
  white75: 'rgba(255, 255, 255, 0.75)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white15: 'rgba(255, 255, 255, 0.15)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white5: 'rgba(255, 255, 255, 0.05)',

  darkCharcoal: '#2f2f2f',
  eerieBlack: '#1f1f1f',
  ghostWhite: '#f9f9fb',
  ghostWhite75: 'rgba(249, 249, 251, 0.75)',
  ghostWhite50: 'rgba(249, 249, 251, 0.50)',
  arsenic60: 'rgba(43, 43, 43, 0.6)',
  arsenic90: 'rgba(43, 43, 43, 0.9)',
  brightGray: '#ececee',
}
